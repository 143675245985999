/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-animated-css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  ActionAnimations,
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as HeadsUpStore from '../../../store/HeadsUp/HeadsUp';
import * as DemoStore from '../../../store/Demo/Demo';

import { connect, ConnectedProps } from 'react-redux';

import TrainingSuggestion from '../../Home/HomePage/TrainingSuggestion';
import HeadsUpTargets from '../../Shared/HeadsUp/HeadsUpTargets';
import { ScenarioSuggestion } from '../../../store/Demo/ScenarioBuilder';
import { AssetKeys } from '../../../AssetKeys';
import { Card } from '../Card';
import Suggestion from '../../Suggestion/Suggestion/Suggestion';
import { DEFAULT_SUGGESTION } from '../../Suggestion/SuggestionUtils';


const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
  morningBrewState: state.morningBrew,
  demoState: state.demo,
  selectedScenarioState: state.demo.selectedScenario,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
  ...HeadsUpStore.actionCreators,
  ...DemoStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = {
  selectedScoringEntity?: string,
} & PropsFromRedux;

const Home: React.FC<IProps> = (props) => {  
  const [swipeCardProgress, setSwipeCardProgress] = useState(0);
  const [showCardSwipeText, setShowCardSwipeText] = useState(false);
  const [showCardSwipeIcons, setShowCardSwipeIcons] = useState(false);
  const [suggestions, setSuggestions] = useState<ScenarioSuggestion[]>([]);
  // static variables
  const maxStackedCards = 3;
  const cardAnimationDuration = 1000; // milliseconds
  const showCardSwipeIconsThreshold = 15; // percentage
  const showCardSwipeTextThreshold = 25; // percentage
  const fullSwipeCardThreshold = 50; // percentage
  let removeActionedSuggestionTimer: any;
  const newSuggestionAudio = new Audio(AssetKeys.new_suggestion_sound_effect);
  const selectedScenario = props.selectedScenarioState;
  const selectedSuggestions = selectedScenario?.suggestions;

  function sortSuggestions(suggestionsToSort: ScenarioSuggestion[]){
    return suggestionsToSort.sort((a, b) => (b.priority + b.obscurity) - (a.priority + a.obscurity));
  }

  useEffect(() => {
    const { authentication, requestHeadsUpTargets } = props;

    
    const selectedScoringEntity = props.selectedScoringEntity ? props.selectedScoringEntity : authentication.selectedScoringEntity;

    if(selectedScoringEntity && selectedScoringEntity.length > 0) {
      requestHeadsUpTargets(selectedScoringEntity, new Date().toISOString());
    }

    if(selectedSuggestions && selectedSuggestions.length > 0){
      const sortedSuggestions = sortSuggestions(selectedSuggestions);
      const elapsedSeconds = Math.floor((Date.now() - selectedScenario?.initializedAt) / 1000);

      const activeSuggestions = sortedSuggestions.filter(suggestion => suggestion.showAfter < elapsedSeconds);
      setSuggestions(activeSuggestions);

      const suggestionsToLoad = sortedSuggestions.filter(suggestion => !activeSuggestions.includes(suggestion));
      suggestionsToLoad.forEach(suggestion => {
        setTimeout(() => {
          setSuggestions(prevState => {
            const newSuggestions = sortSuggestions([ ...prevState, suggestion ]);
            return [...newSuggestions];
          });
        }, (suggestion.showAfter - elapsedSeconds) * 1000);
      });
    }
  }, []);



  useEffect(() => {
    if(suggestions.length > 0){
      const sortedSuggestions = suggestions.sort((a, b) => (b.priority + b.obscurity) - (a.priority + a.obscurity));
      if(sortedSuggestions[0] != suggestions[0]){
        playSuggestionAudio(sortedSuggestions[0], suggestions[0]);
      }
    }
  }, [suggestions]);

  function playSuggestionAudio(newSuggestion: ScenarioSuggestion, oldSuggestion: ScenarioSuggestion){
    if ((newSuggestion.priority + newSuggestion.obscurity) >= (oldSuggestion.priority + oldSuggestion.obscurity) && newSuggestion.title !== oldSuggestion.title) {
      newSuggestionAudio.play();
    }
  }

  const handleSwipeEnd = (suggestion: ScenarioSuggestion) => {
    if (swipeCardProgress >= fullSwipeCardThreshold) {
      removeActionedSuggestion(suggestion);
      setTimeout(() => {
        clearTimeout(removeActionedSuggestionTimer);
      }, cardAnimationDuration);
    }
    setSwipeCardProgress(0);
  };

  const handleSwipeProgress = (progress: number) => {
    setSwipeCardProgress(progress);
    setShowCardSwipeText(false);
    setShowCardSwipeIcons(false);

    if (progress >= showCardSwipeIconsThreshold) {
      setShowCardSwipeIcons(true);
    }

    if (swipeCardProgress >= showCardSwipeTextThreshold) {
      setShowCardSwipeText(true);
    }
  };

  const removeActionedSuggestion = (actionedSuggestion: ScenarioSuggestion) => {
    if (swipeCardProgress >= fullSwipeCardThreshold) {
      const selectedSuggestions = props.selectedScenarioState?.suggestions;
      if(selectedSuggestions && selectedSuggestions.length > 0){
        props.updateSelectedScenario(selectedSuggestions.filter(s => s.id !== actionedSuggestion.id));
      }

      removeActionedSuggestionTimer = setTimeout(() => {
        const actionedSuggests = suggestions.filter((s) => s.id !== actionedSuggestion.id);
        setSuggestions(actionedSuggests);
      }, cardAnimationDuration);
    }
  };

  const renderCards = () => {
    const stackedCards = suggestions.slice(1, maxStackedCards + 1)
      .map((s, i) => <div key={s.id}
        className={`card card-${i+1}`}></div>);

    return stackedCards.length > 0 && (
      <div className='stacked-cards'>
        {stackedCards}
      </div>
    );
  };

  const getCardAnimationType = () => {
    return swipeCardProgress < 50 ? ActionAnimations.RETURN : ActionAnimations.REMOVE;
  };

  const hasNoSuggestions = () => {
    return !props.demoState.isLoading && suggestions.length === 0;
  };

  return (
    <div className="app">
      <div className="body-wrapper">
        <h1 className="title">Digital Store Assistant</h1>
      </div>
      {
        props.suggestionState && props.suggestionState.displayTrainingSuggestions ?
          <Animated
            key='default'
            animationIn="bounceIn"
            animationOut="bounceOut"
            animationInDuration={cardAnimationDuration}
            animationOutDuration={cardAnimationDuration}
            isVisible={true}>
            <TrainingSuggestion />
          </Animated>
          :
          <>
            {
              suggestions.length === 0 && props.suggestionState.isLoading &&
                <Animated
                  key='default'
                  animationIn="bounceIn"
                  animationOut="zoomOut"
                  animationInDuration={cardAnimationDuration}
                  animationOutDuration={cardAnimationDuration}
                  isVisible={true}>
                  <Card
                    loading={props.suggestionState.isLoading}
                  />
                </Animated>
            }
            {
              hasNoSuggestions() &&
                <Animated
                  key='default'
                  animationIn="bounceIn"
                  animationOut="zoomOut"
                  animationInDuration={cardAnimationDuration}
                  animationOutDuration={cardAnimationDuration}
                  isVisible={true}>
                  <Suggestion
                    suggestion={DEFAULT_SUGGESTION}
                    blockSwipe={true}
                  />
                </Animated>
            }
            {
              (suggestions && suggestions.length > 0) &&
                <SwipeableList threshold={0.5}
                  key={suggestions[0].id}>
                  {({ className }) => (
                    <TransitionGroup
                      className={className}
                      enter={true}
                      exit={true}
                    >
                      <CSSTransition
                        classNames="my-node"
                        timeout={3500}
                      >
                        <SwipeableListItem
                          threshold={0.1}
                          swipeLeft={{
                            content: <div className='delete-action'>
                              <div className={`delete-image incomplete ${showCardSwipeIcons && 'display-icon'}`} />
                              <span className={`info-text ${showCardSwipeText && 'display-text'}`}><b>Task Not Completed</b></span>
                            </div>,
                            actionAnimation: getCardAnimationType(),
                            action: () => {},
                          }}
                          swipeRight={{
                            content: <div className='accept-action'>
                              <div className={`accept-image complete ${showCardSwipeIcons && 'display-icon'}`} />
                              <span className={`info-text ${showCardSwipeText && 'display-text'}`}><b>Task Completed</b></span>
                            </div>,
                            actionAnimation: getCardAnimationType(),
                            action: () => {},
                          }}
                          onSwipeEnd={() => handleSwipeEnd(suggestions[0])}
                          onSwipeProgress={(progress) => { handleSwipeProgress(progress); }}
                        >
                          <Card
                            title={suggestions[0].title}
                            desc={suggestions[0].description}
                          />
                            
                        </SwipeableListItem>
                      </CSSTransition>
                    </TransitionGroup>
                  )}
                </SwipeableList>
            }
            {
              suggestions && !props.authentication.isLoading &&  renderCards()
            }
            <HeadsUpTargets />
          </>
      }
    </div>
  );
};

export default connector(Home);