import { ApexOptions } from 'apexcharts';

const chartMaxValueColour = '#f2cd00';
const chartDefaultColour = '#d4d4d4';
const chartTitleColour = '#7a7979';
const chartCurrentTimeColour = '#e04e39';
const chartBreakTimesColour = '#9dc47a';

export const breakTimeAnnonations: ApexAnnotations = {};

export const salesForecastOptions: ApexOptions = {
  chart: {
    stacked: true,
    background: '#fafcff',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: true,
    },
  },

  states: {
    hover: {
      filter: {
        type: '',
        value: 0,
      },
    },  

    active: {
      filter: {
        type: '',
        value: 0,
      },
    },
  },

  dataLabels: {
    enabled: false,
  },

  annotations: breakTimeAnnonations,

  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },

    yaxis: {
      lines: {
        show: false,
      },
    },
  },

  xaxis: {
    labels: {
      show: true,
    },

    categories: [''],

    axisBorder: {
      show: true,
      color: chartDefaultColour,
      offsetX: 0,
      offsetY: 0,
    },
  },

  yaxis: {
    labels: {
      show: false,
    },

    axisBorder: {
      show: true,
      color: chartDefaultColour,
      offsetX: 0,
      offsetY: 0,
    },
  },

  legend: {
    show: true,
    position: 'bottom',
    onItemHover: {
      highlightDataSeries: true,
    },
    showForSingleSeries: true,
  },

  colors: [chartDefaultColour, chartMaxValueColour, chartCurrentTimeColour, chartBreakTimesColour],
  title: {
    text: 'Forecasted Sales',
    align: 'center',
    margin: 20,
    offsetX: 0,
    offsetY: 0,
    floating: true,
    style: {
      fontSize: '21px',
      fontWeight: 'bold',
      fontFamily: '',
      color: chartTitleColour,
    },
  },
};

export const salesForecastSeries: StatisticsData[] = [
  {
    name: 'Average Transactions per Hour',
    data: [],
  },
  {
    name: 'Busiest Hour',
    data: [],
  },
  {
    name: 'Current hour',
    data: [],
  },
  {
    name: 'Best Break Times',
    data: [],
  },
];

export interface StatisticsData {
  name: string,
  data: number[]
}
