import React from 'react';
import { Loading } from '../Shared/Loading/Loading';


type Props = {
  title?: string, 
  desc?: string,  
  loading?: boolean,
  className?: string,
  pinned?: boolean
}

export const Card = (props: Props) => {
  return (
    <div className={`suggestion ${props.loading ? 'dim' : '' } ${props.className ? props.className : '' }`}>
      {
        props.pinned && <span className='card-icon'>
          <img src="/assets/pin.png"
            alt="Pin Icon" />
        </span>
      }
      {
        props.loading 
          ? 
          <Loading />
          :
          <>
            <h3 className="title">{props.title}</h3>
            <p className="desc">{props.desc}</p>
          </>
      }
    </div>
  );
};