/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import Home from './components/Home/HomePage/HomePage';
import Scorecard from './components/Scorecard/ScorecardPage/ScorecardPage';
import History from './components/History/HistoryPage/HistoryPage';
import Admin from './components/Admin/AdminPage/AdminPage';
import Login from './components/Login/LoginPage/LoginPage';
import './App.scss';
import NotFound from './components/NotFound/NotFoundPage/NotFoundPage';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import * as AuthenticationStore from './store/Authentication/Authentication';
import * as ConfigurationStore from './store/Configuration/Configuration';
import MorningBrew from './components/MorningBrew/MorningBrewPage/MorningBrewPage';
import DailyForecast from './components/MorningBrew/DailyForecastPage/DailyForecastPage';
import ContentPanel from './components/Shared/ContentPanel/ContentPanel';
import PermissionHelper from './helpers/PermissionHelper';
import { Permissions } from './store/Authentication/Authentication';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import IdleHandler from './components/Shared/IdleHandler/IdleHandler';
import DemoPage from './components/Demo/DemoPage/DemoPage';
import ScenarioBuilder from './components/Demo/ScenarioBuilder/ScenarioBuilder';
import DemoHomePage from './components/Demo/DemoHomePage/DemoHomePage';
import QueueStatus from './components/Demo/QueueStatusPage/QueueStatusPage';
import QueueStatusPage from './components/Demo/QueueStatusPage/QueueStatusPage';
import React from 'react';


interface AppProps {
  authentication: AuthenticationStore.AuthenticationState;
  configuration: ConfigurationStore.ConfigurationState;
}

function App(props: AppProps) {
  const { authentication, configuration } = props;
  const permHelper = new PermissionHelper(authentication.permissions);

  if(configuration.appInsightsConfig && window.location.hostname !== 'localhost') {
    const appInsights = new ApplicationInsights({ config: {
      connectionString: configuration.appInsightsConfig,
      autoTrackPageVisitTime: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAutoRouteTracking: true,
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  function isInDemoScenario(){
    return permHelper.HasPermission(Permissions.ViewScenarios) && props.authentication.isInDemo;
  }

  function hasBasicAccess(){
    return permHelper.IsAdmin() || permHelper.IsUser() || isInDemoScenario();
  }

  function getHomeElement(){
    if(isInDemoScenario()){
      return <DemoHomePage/>;
    }
    if(permHelper.IsAdmin()){
      return <Admin/>;
    }
    if(permHelper.IsDemoUser()){
      return <DemoPage/>;
    }
    return <Home/>;
  }

  return (
    <BrowserRouter>
      <ContentPanel>
        <AuthenticatedTemplate>
          <IdleHandler />
          <Routes>
            <Route path="/"
              element={getHomeElement()}
            />
            <Route path="/History"
              element={hasBasicAccess() ? <History/> : <NotFound/>}
            />
            <Route path="/Admin"
              element={permHelper.IsAdmin() ? <Admin/> : <NotFound/>}
            />
            <Route path="/Scorecard"
              element={hasBasicAccess() ? <Scorecard/> : <NotFound/>}
            />
            <Route path="/morning-brew"
              element={hasBasicAccess() ? <MorningBrew/> : <NotFound/>}
            />
            <Route path="/daily-forecast"
              element={hasBasicAccess() ? <DailyForecast/> : <NotFound/>}
            />
            <Route path="/demo"
              element={permHelper.HasPermission(Permissions.ViewScenarios) ? <DemoPage/> : <NotFound/>}
            />            
            <Route path="/scenario-builder" 
              element={permHelper.HasPermission(Permissions.EditScenarios) ? <ScenarioBuilder/> : <NotFound/>}
            />
            <Route path="/scenario-builder/:id"
              element={permHelper.HasPermission(Permissions.EditScenarios) ? <ScenarioBuilder/> : <NotFound/>} />
            <Route path="/queue"
              element={permHelper.IsAdmin() ? <QueueStatusPage/> : <NotFound/>}
            />  
            <Route path="/*"
              element={<NotFound/>}
            />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/*"
              element={<Login />}
            />
          </Routes>
        </UnauthenticatedTemplate>
      </ContentPanel>
    </BrowserRouter>
  );
}

export default connect(
  (state: ApplicationState) => ({
    authentication: state.authentication,
    configuration: state.configuration,
  }),
)(App as any);