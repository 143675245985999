import * as History from './History/History';
import * as Suggestion from './Suggestion/Suggestion';
import * as Authentication from './Authentication/Authentication';
import * as MorningBrew from './MorningBrew/MorningBrew';
import * as DailyForecast from './DailyForecast/DailyForecast';
import * as WeatherData from './Weather/Weather';
import * as Reminder from './Reminder/Reminder';
import * as HeadsUp from './HeadsUp/HeadsUp';
import * as Configuration from './Configuration/Configuration';
import * as Demo from './Demo/Demo';
import * as ScenarioBuilder from './Demo/ScenarioBuilder';
import * as QueueStatus from './Demo/QueueStatus';

// The top-level state object
export interface ApplicationState {
    history: History.HistoryState;
    suggestion: Suggestion.SuggestionState;
    authentication: Authentication.AuthenticationState;
    morningBrew: MorningBrew.MorningBrewState;
    dailyForecast: DailyForecast.DailyForecastState;
    weatherData: WeatherData.WeatherState;
    reminder: Reminder.ReminderState;
    headsUp: HeadsUp.HeadsUpState;
    configuration: Configuration.ConfigurationState;
    demo: Demo.DemoState;
    scenarioBuilder: ScenarioBuilder.ScenarioSuggestionState;
    queueStatus: QueueStatus.QueueStatusState;
}

// Whenever an action is dispatched, Redux will update each top-level
// application state property using
// the reducer with the matching name. It's important that the names
// match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  history: History.reducer,
  suggestion: Suggestion.reducer,
  authentication: Authentication.reducer,
  morningBrew: MorningBrew.reducer,
  dailyForecast: DailyForecast.reducer,
  weatherData: WeatherData.reducer,
  reminder: Reminder.reducer,
  headsUp: HeadsUp.reducer,
  configuration: Configuration.reducer,
  demo: Demo.reducer,
  scenarioBuilder: ScenarioBuilder.reducer,
  queueStatus: QueueStatus.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch'
// and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
