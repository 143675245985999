import  React, {  useEffect, useState } from 'react';
import { SuggestionCategory } from '../../../store/Suggestion/Suggestion';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import Textarea from '../../Shared/TextArea/TextArea';
import * as ScenarioBuilderStore from '../../../store/Demo/ScenarioBuilder';
import { useParams } from 'react-router';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import { Loading } from '../../Shared/Loading/Loading';
import { LogLevel } from '@azure/msal-browser';

const mapState = (state: ApplicationState) => ({
  scenarioSuggestionState: state.scenarioBuilder,
  authentication: state.authentication,
});

const mapDispatch = {
  ...ScenarioBuilderStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

function ScenarioBuilder(props: IProps) {
  const { requestScenarioSuggestions, createScenarioSuggestion, updateScenarioSuggestion, deleteScenarioSuggestion, clearSuggestions } = props;
  const { id } = useParams();

  useEffect(() => {
    if(!id){
      clearSuggestions();
    }
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(id!)))
    {
      requestScenarioSuggestions(parseInt(id!));    
    }
  }, [id, requestScenarioSuggestions]);

  const selectedScenario = useSelector((state: ApplicationState) => state.scenarioBuilder.selectedScenario);

  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editedSuggestion, setEditedSuggestion] = useState<ScenarioBuilderStore.ScenarioSuggestion | undefined>();  
  const [newSuggestion, setNewSuggestion] = useState<ScenarioBuilderStore.ScenarioSuggestion>(createScenario());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);


  function createScenario() {
    return {
      id: 0,
      scenarioId : 0,
      scenarioTitle: selectedScenario?.title ?? 'Scenario',
      title: '',
      description: '',
      priority: 0,
      obscurity: 0,
      category: Object.keys(SuggestionCategory)[0],
      showAfter: 0,
    };
  }

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setEditedSuggestion((prev) => ({ ...prev!, [name]: value }));
  };
  
  const handleTextAreaChange = (
    value: React.ChangeEvent<HTMLTextAreaElement>,
    name: string,
  ) => {
    setEditedSuggestion((prev) => ({ ...prev!,  [name]:value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setEditedSuggestion((prev) => ({ ...prev!, category: value }));
  };

  const handleSaveClick = () => {
    const isValid = validateForm();
    if (isValid) {
      const updatedSuggestions = selectedScenario?.suggestions.map((s) =>
        s === editedSuggestion ? { ...s, ...editedSuggestion } : s,
      );
      const updatedScenario = { ...selectedScenario, suggestions: updatedSuggestions };
      updateScenarioSuggestion(editedSuggestion!);
      setIsEditing(false);
      setErrorMessage('');
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedSuggestion(undefined);
  };

  const handleEditClick = (scenarioSuggestion: ScenarioBuilderStore.ScenarioSuggestion) => {
    setIsEditing(true);
    setEditedSuggestion(scenarioSuggestion);
  };

  const handleDeleteClick = (id: number) => {
    setDeleteId(id);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = () => {
    deleteScenarioSuggestion(deleteId);
    setShowConfirmationModal(false);
  };

  const handleAddClick = () => {
    setIsCreating(true);
  };

  const handleShowAtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditedSuggestion((prev) => ({ ...prev!, showAfter: parseInt(value) }));
  };

  const handleNewInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSuggestion((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNewSelectChange = ( event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    setNewSuggestion((prev) => ({ ...prev!, [name]: value }));
  };

  const handleNewShowAtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSuggestion((prevState) => ({ ...prevState, [name]: parseInt(value) }));
  };

  const [errorMessage, setErrorMessage] = useState('');
  const validateForm = () => {
    let isValid = true;
    if (isCreating) {
      if (!newSuggestion.title || !newSuggestion.description) {
        setErrorMessage('Title and Description fields are required');
        isValid = false;
      }
    } else if (isEditing) {
      if (!editedSuggestion?.title || !editedSuggestion.description) {
        setErrorMessage('Title and Description fields are required');
        isValid = false;
      }
    }
    return isValid;
  };

  const handleNewCancelClick = () => {
    setIsCreating(false);
    setNewSuggestion(createScenario());
  };

  const handleNewSaveClick = () => {
    const isValid = validateForm();
    if (isValid) {
      newSuggestion.scenarioId = !isNaN(parseInt(id!)) ? parseInt(id!) : selectedScenario?.id ?? 0;
      newSuggestion.scenarioTitle = editedSuggestion?.scenarioTitle ?? selectedScenario?.title ?? newSuggestion.scenarioTitle;
      createScenarioSuggestion(newSuggestion);
      setIsCreating(false);
      setNewSuggestion(createScenario());
      setErrorMessage('');
    }
   
  };

  return (
    <div className="app">
      <div className="body-wrapper">
        <h1 className="title">Scenario Builder</h1>
        <div className='table-wrapper'> 
          <div className='left-align'>
            <input
              type="text"
              name="scenarioTitle"
              placeholder="Scenario Title"
              maxLength={50}
              value={editedSuggestion?.scenarioTitle ?? selectedScenario?.title}
              onChange={handleInputChange}
            />
          </div>
          <div className='left-align'>
            <h4 className='suggestion-list'>Suggestion List</h4>
          </div>
          <form onSubmit={(e)=>handleSubmit(e)}>   
            <table className='scenario-suggestions'>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Priority</th>
                  <th>Obscurity</th>
                  <th>Category</th>
                  <th>Show At (seconds)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.scenarioSuggestionState.isLoading ? 
                    <tr><td colSpan={7}><Loading /></td></tr>
                    :
                    <>
                      {selectedScenario && selectedScenario.suggestions.map(suggestion => {
                        return (
                          <tr key={suggestion.id}>
                            <td
                              className='suggestion-description '>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <Textarea
                                  name="title"
                                  label=""
                                  placeholderText="Title"
                                  required={true}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e, 'title')}
                                  value={editedSuggestion?.title ?? ''} />
                              ) : (
                                suggestion.title
                              )}
                            </td>
                            <td
                              className='suggestion-description '>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <Textarea
                                  name="description"
                                  label=""
                                  placeholderText="Description"
                                  required={true}
                                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e, 'description')}
                                  value={editedSuggestion?.description ?? ''} />

                              ) : (
                                suggestion.description
                              )}
                            </td>
                            <td>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <input
                                  type="text"
                                  name="priority"
                                  value={editedSuggestion?.priority ?? ''}
                                  onChange={handleInputChange} />
                              ) : (
                                suggestion.priority
                              )}
                            </td>
                            <td>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <input
                                  type="text"
                                  name="obscurity"
                                  value={editedSuggestion?.obscurity ?? ''}
                                  onChange={handleInputChange} />
                              ) : (
                                suggestion.obscurity
                              )}
                            </td>
                            <td>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <select name="category"
                                  value={editedSuggestion?.category}
                                  onChange={handleSelectChange}>
                                  {Object.keys(SuggestionCategory).map((category) => (
                                    <option key={category}
                                      value={category}>
                                      {category}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                suggestion.category
                              )}
                            </td>
                            <td>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <input
                                  type="number"
                                  name="showAfter"
                                  value={editedSuggestion?.showAfter ?? ''}
                                  onChange={handleShowAtChange} />
                              ) : (
                                suggestion.showAfter
                              )}
                            </td>
                            <td>
                              {isEditing && suggestion.id === editedSuggestion?.id ? (
                                <>
                                  <button className='save-button'
                                    type="submit"
                                    onClick={handleSaveClick}
                                  >Save</button>  
                                  <button className='cancel-button'
                                    onClick={handleCancelClick}>Cancel</button>
                                </>
                              ) : (
                                <>
                                  <button className='btn-img'
                                    onClick={() => handleEditClick(suggestion)}> <img className='img-style'
                                      src="/assets/edit.png"
                                      alt="Edit" /></button>
                                  <button className='btn-img'
                                    onClick={() => handleDeleteClick(suggestion.id)}><img className='img-style'
                                      src="/assets/bin.png"
                                      alt="Delete" /></button>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </> }
                {isCreating ? (
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="title"
                        placeholder='Enter Title'
                        required={true}
                        value={newSuggestion?.title}
                        onChange={handleNewInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="description"
                        required={true}
                        placeholder='Enter Description'
                        value={newSuggestion?.description}
                        onChange={handleNewInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="priority"
                        placeholder='Enter Priority'
                        value={newSuggestion?.priority}
                        onChange={handleNewInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="obscurity"
                        placeholder='Enter Obscurity'
                        value={newSuggestion?.obscurity}
                        onChange={handleNewInputChange}
                      />
                    </td>
                    <td>
                      <select name="category"
                        value={newSuggestion?.category}
                        onChange={handleNewSelectChange}>
                        {Object.keys(SuggestionCategory).map((category) => (
                          <option key={category}
                            value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="number"
                        name="showAfter"
                        placeholder='Enter ShowAt'
                        value={newSuggestion?.showAfter}
                        onChange={handleNewShowAtChange}
                      />
                    </td>
                    <td>
                      <button className='save-button'
                        onClick={handleNewSaveClick}>Save</button>
                      <button className='cancel-button'
                        onClick={handleNewCancelClick}>Cancel</button>
                    </td>
                  </tr>
              
                ) : null}

                <tr><td colSpan={7}><button  className='btn-img'
                  onClick={handleAddClick}> <img className='create-img-style'
                    src="/assets/plus.jpg"
                    alt="Add" /></button></td></tr>
              </tbody>
            </table>
          </form>
       

          {showConfirmationModal &&
            <ConfirmationModal
              onCancel={() => setShowConfirmationModal(false)}
              onContinue={handleDeleteConfirmation}
              cancelText='Cancel'
              continueText='Yes'
              description='This will delete selected record. Would you like to continue?'
              title='Are you sure?'
            />
          }
        </div>
      </div>
    </div>
  );
}

export default connector(WithRouter(ScenarioBuilder));

function setFormError(arg0: string) {
  throw new Error('Function not implemented.');
}

