import 'react-circular-progressbar/dist/styles.css';

type Props = {
    value: number,
    valueSuffix?: string,
    maxValue: number,
    minValue? : number
    className?: string,
    showValue?: boolean,
}
  
function ProgressBar(props: Props) {
  const getBackgroundSize = () => {
    return { backgroundSize: `${(props.value * 100) / props.maxValue}% 100%` };
  };

  return (
    <div className='threshold-input-wrapper'>
      { props.showValue && 
        <label className="threshold-input-value">{ `${props.value} ${props.valueSuffix ?? ''}` }</label> }
      <input
        type="range"
        min={props.minValue || 0}
        max={props.maxValue}
        style={getBackgroundSize()}
        value={props.value}
      />
    </div>
  );
}

export default ProgressBar;