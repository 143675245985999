import { Permissions } from '../store/Authentication/Authentication';

class PermissionHelper{
  private currentPermissions;
  constructor(currentPermissions: number[]){
    this.currentPermissions = currentPermissions;
  }

  HasPermission(permission: Permissions){
    return this.currentPermissions && this.currentPermissions.indexOf(permission) > -1;
  }

  IsAdmin(){
    return this.HasPermission(Permissions.ViewAdminDashboard);
  }

  IsUser(){
    return this.HasPermission(Permissions.ViewSuggestionsHistory) && !this.IsAdmin();
  }

  IsDemoUser(){
    return this.HasPermission(Permissions.ViewScenarios) && !this.IsAdmin();
  }
}

export default(PermissionHelper);
