/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface QueueStatusState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    queueStatus: QueueStatus;
    loadedOnce: boolean;
}

export interface QueueStatus {
  count: Count,
  timeToServeInSec: number,
  averageWaitTimeInSec: number,
  timeStamp: Date
}

export interface Count {
    zone: number;
    queueIn: number;
    queueOut: number;
    queueAbandonment: number;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestScenariosAction extends AnyAction {
    type: 'REQUEST_QUEUE_STATUS';
}

export interface ReceiveScenariosAction extends AnyAction {
    type: 'RECEIVE_REQUESTED_QUEUE_STATUS';
    payload: any;
}

export interface FailedScenariosAction extends AnyAction {
    type: 'FAILED_REQUESTED_QUEUE_STATUS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestScenariosAction | ReceiveScenariosAction | FailedScenariosAction;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestQueueStatus: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.queueStatus && appState.queueStatus.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_QUEUE_STATUS',
        http: {
          verb: 'GET',
          endpoint: 'api/statistics/queuestatus',
          successAction: 'RECEIVE_REQUESTED_QUEUE_STATUS',
          failureAction: 'FAILED_REQUESTED_QUEUE_STATUS',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: QueueStatusState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  loadedOnce: false,
  queueStatus: {
    count: {
      queueAbandonment: 0,
      queueIn: 0,
      queueOut: 0,
      zone: 0,
    },
    averageWaitTimeInSec: 0,
    timeToServeInSec: 0,
    timeStamp: new Date(),
  },
};

export const reducer: Reducer<QueueStatusState> = (state: QueueStatusState | undefined,
  incomingAction: Action):QueueStatusState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_QUEUE_STATUS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: state.loadedOnce ? false : true,
      queueStatus: state.queueStatus,
    };
  case 'RECEIVE_REQUESTED_QUEUE_STATUS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      queueStatus: action.payload,
      loadedOnce: true,
    };
  case 'FAILED_REQUESTED_QUEUE_STATUS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
      loadedOnce: false,
    };
  default:
    return state;
  }
};