import { AssetKeys } from '../../../AssetKeys';
import { Suggestion as SuggestionModel } from '../../../store/Suggestion/Suggestion';
import { Loading } from '../../Shared/Loading/Loading';
import { Swipeable, SwipeableContent } from '../../Shared/Swipeable/Swipeable';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as MorningBrewStore from '../../../store/MorningBrew/MorningBrew';
import * as HeadsUpStore from '../../../store/HeadsUp/HeadsUp';
import { ApplicationState } from '../../../store';
import { connect, ConnectedProps } from 'react-redux';
import DateUtils from '../../../helpers/DateUtils';
import SubSuggestion from '../SubSuggestion/SubSuggestion';
import {
  ActionSuggestionSwipeContent,
  ANIMATION_DURATION,
  ANIMATION_DURATION_LONGER,
  DefaultSwipeContent,
  EmptySwipeContent,
  FULL_SWIPE_THRESHOLD,
  getCelebrationSuggestion,
  IsCelebrationSuggestion,
  IsMorningBrewSuggestion,
  IsQueueSuggestion,
  IsReminderSuggestion,
  IsSubSuggestion,
  MorningBrewSwipeContent,
  QUEUE_INTERVAL,
  ReminderSwipeContent,
  SWIPE_ACTION_THRESHOLD,
} from '../SuggestionUtils';

const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
  morningBrewState: state.morningBrew,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
  ...MorningBrewStore.actionCreators,
  ...HeadsUpStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = {
  suggestion: SuggestionModel
  loading?: boolean,
  className?: string,
  pinned?: boolean,
  blockSwipe: boolean,
  onSwipe?: Function,
} & PropsFromRedux;

const newSuggestionAudio = new Audio(AssetKeys.new_suggestion_sound_effect);
const vibrationDuration = 500; //milliseconds

const Suggestion = (props: Props) => {
  const [subSuggestionIndex, setSubSuggestionIndex] = useState(0);
  const [blockSuggestionSwipe, setBlockSuggestionSwipe] = useState(false);
  const [swipeCardProgress, setSwipeCardProgress] = useState(0);
  const [actionSuggestionShown, setActionSuggestionShown] = useState(false);
  const [swipeContent, setSwipeContent] = useState<SwipeableContent | undefined>(undefined);
  const slideshowIntervalIdRef = useRef<NodeJS.Timer>();
  const cleanupSlideshowInterval = () => clearInterval(slideshowIntervalIdRef.current!);
  const navigate = useNavigate();

  const { id, timeSlot, subSuggestions, title, description, category, seen, type } = props.suggestion;

  useEffect(() => {
    if (id > 0 && !seen && !props.authentication.mimicScoringEntity) {
      markSuggestionSeen();
    }
    if (IsQueueSuggestion(type)) {
      setBlockSuggestionSwipe(true);
    }

    if(!swipeContent) {
      setSwipeContent(getSuggestionSwipeContent(category));
    }

    return () => {
      cleanupSlideshowInterval();
    };
  }, [props.suggestion]);

  useEffect(() => {
    if(actionSuggestionShown){
      setSwipeContent(getSuggestionSwipeContent(category));
    }
  }, [actionSuggestionShown]);

  const slideShowSubSuggestions = useCallback(() => {
    if (IsQueueSuggestion(type) && subSuggestions && subSuggestions.length > 1)
      slideshowIntervalIdRef.current = setInterval(() => {
        setSubSuggestionIndex((prevIndex) => (prevIndex + 1) % subSuggestions!.length);
      }, QUEUE_INTERVAL);
  }, [subSuggestions]);

  useEffect(() => {
    slideShowSubSuggestions();
    return cleanupSlideshowInterval;
  }, [slideShowSubSuggestions]);

  const markSuggestionSeen = () => {
    const { suggestion } = props;

    suggestion.seen = DateUtils.getLocalDate();
    if (typeof window.navigator?.vibrate === 'function') {
      window.navigator.vibrate(vibrationDuration);
    }

    newSuggestionAudio.play();

    const data: SuggestionStore.SuggestionUpdateModel = {
      storeId: props.authentication.selectedScoringEntity,
      id: id,
      timeslot: timeSlot,
      seen: DateUtils.getLocalDate(),
    };
    props.updateSuggestion(data);
  };

  const getSuggestionSwipeContent = (category: string): SwipeableContent => {
    if (actionSuggestionShown) {
      return ActionSuggestionSwipeContent;
    }
    if(IsMorningBrewSuggestion(title)) {
      return MorningBrewSwipeContent;
    }

    switch (category) {
    case SuggestionStore.SuggestionCategory.Celebration:
      return getCelebrationSuggestion(swipeContent ? true : false);
    case SuggestionStore.SuggestionCategory.Reminder:
      return ReminderSwipeContent;
    default:
      return DefaultSwipeContent;
    }
  };

  const getRemovalDuration = () => {
    if(category == SuggestionStore.SuggestionCategory.Celebration) {
      return ANIMATION_DURATION_LONGER;
    }
    return ANIMATION_DURATION;
  }; 

  const handleSubSuggestionRemoval = () => {
    const isLastSubSuggestion = subSuggestionIndex + 1 === subSuggestions?.length;
    if (isLastSubSuggestion) {
      handleSuggestionSwipeAction(true, undefined, true);
      removeSuggestion();
    } else {
      setSubSuggestionIndex(subSuggestionIndex + 1);
    }
  };

  const handleSwipeEnd = () => {
    const requiresOnlyOneSwipe = IsCelebrationSuggestion(category) ||
      IsReminderSuggestion(category);

    if (swipeCardProgress >= FULL_SWIPE_THRESHOLD) {
      if (requiresOnlyOneSwipe || actionSuggestionShown) {
        removeSuggestion();
      }

      !requiresOnlyOneSwipe && setTimeout(() => setActionSuggestionShown(!actionSuggestionShown), ANIMATION_DURATION);

      if (IsMorningBrewSuggestion(title)) {
        removeSuggestion();
        setTimeout(() => navigate('/morning-brew'), ANIMATION_DURATION);
      }
    }
    setSwipeCardProgress(0);
    props.onSwipe && props.onSwipe(false);
  };

  const handleSwipeProgress = (progress: number, suggestion: SuggestionStore.Suggestion) => {
    setSwipeCardProgress(progress);
    props.onSwipe && props.onSwipe(true);
  };

  const removeSuggestion = () => {
    setTimeout(() => {
      props.removeSuggestion(id, timeSlot);
    }, getRemovalDuration());
  };

  const handleSuggestionSwipeAction = (value: boolean, subSuggestionId?: number, lastSubSuggestionSwiped?: boolean) => {
    const update: SuggestionStore.SuggestionUpdateModel = {
      storeId: props.authentication.selectedScoringEntity,
      id: id,
      timeslot: timeSlot,
    };

    let updated = false;

    const requiresOnlyOneSwipe = IsCelebrationSuggestion(category) || IsReminderSuggestion(category);

    if(swipeCardProgress >= FULL_SWIPE_THRESHOLD || subSuggestionId || lastSubSuggestionSwiped) {
      if (requiresOnlyOneSwipe || actionSuggestionShown) {
        update.actioned = true;
        update.complete = value;
        update.dismissed = DateUtils.getLocalDate();
        updated = true;

        if (IsSubSuggestion(subSuggestionId)) {
          update.subSuggestionId = subSuggestionId;
        }

        if (IsCelebrationSuggestion(category)) {
          update.complete = true;
          update.pinned = true;
        }
      } else {
        if (IsMorningBrewSuggestion(title)) {
          update.actioned = true;
          update.dismissed = DateUtils.getLocalDate();
        } else {
          update.helpful = value;
        }
        updated = true;
      }
    } else if(!requiresOnlyOneSwipe && !actionSuggestionShown) {
      update.helpful = value;
      updated = true;
    }

    if(updated) {
      props.updateSuggestion(update);
    }
  };

  const getSuggestionContent = (): JSX.Element => {
    if (actionSuggestionShown) {
      return (
        <>
          <h3 className="title">Did you action the suggestion?</h3>
          <p className="desc">In order to gather data on which suggestions are helpful please let us know if you carried out the actions implied on the suggestion.</p>
        </>
      );
    }
    if (subSuggestions) {
      return (
        <>
          <div className='helping-text-subSuggestions'> {subSuggestionIndex + 1} of {subSuggestions?.length} </div>
          <SubSuggestion
            subSuggestion={subSuggestions[subSuggestionIndex]}
            category={category}
            onSwipeThresholdCrossed={(value: boolean, id: number) => handleSuggestionSwipeAction(value, id)}
            onblockSwipe={(value: boolean) => setBlockSuggestionSwipe(value)}
            onRemoveSubSuggestion={() => handleSubSuggestionRemoval()}
            blockSwipe={IsQueueSuggestion(type) || props.blockSwipe}
            timed={IsQueueSuggestion(type)}
            timerDuration={QUEUE_INTERVAL}
          />
          {
            subSuggestions && IsReminderSuggestion(category) &&
            <div className='helping-text-subSuggestions'>
              Swipe to Complete All
            </div>
          }
        </>
      );
    }
    return <>
      <p className="desc">{description}</p>
    </>;
  };

  return (
    <>
      {
        props.loading && <Loading />
      }
      {
        !props.loading &&
        <Swipeable
          key={id + actionSuggestionShown.toString() + timeSlot}
          body={
            <div className={`suggestion ${props.loading ? 'dim' : ''} ${props.className ?? ''}`}>
              {
                props.pinned &&
                <span className='suggestion-icon'>
                  <img src={AssetKeys.pin_icon}
                    alt="Pin Icon" />
                </span>
              }
              {
                !actionSuggestionShown && <h3 className="title">{title}</h3>
              }
              {
                getSuggestionContent()
              }
            </div>
          }
          swipeContent={swipeContent || EmptySwipeContent}
          onSwipeProgress={(progress: number) => handleSwipeProgress(progress, props.suggestion)}
          onSwipeThresholdCrossed={(value: boolean) =>
            handleSuggestionSwipeAction(value)}
          onSwipeEnd={() => handleSwipeEnd()}
          blockSwipe={blockSuggestionSwipe || props.blockSwipe}
          actionThreshold={SWIPE_ACTION_THRESHOLD}
          removeThreshold={FULL_SWIPE_THRESHOLD}
        />
      }
    </>
  );
};

export default connector(Suggestion);