export class AssetKeys {
  public static readonly new_suggestion_sound_effect  = '/assets/audios/newSuggestionSoundEffect.wav';
    
  public static readonly weather : Record<number, string> = {
    0 : '/assets/weather/clear.svg',
    1 : '/assets/weather/partly_cloudly.svg',
    2 : '/assets/weather/partly_cloudly.svg',
    3 : '/assets/weather/cloudly.svg',
    45 : '/assets/weather/fog.svg',
    48 : '/assets/weather/fog.svg',
    51 : '/assets/weather/light_rain.svg',
    53 : '/assets/weather/rain.svg',
    55 : '/assets/weather/rain.svg',
    56 : '/assets/weather/rain.svg',
    57 : '/assets/weather/rain.svg',
    61 : '/assets/weather/light_rain.svg',
    63 : '/assets/weather/rain.svg',
    65 : '/assets/weather/rain.svg',
    66 : '/assets/weather/rain.svg',
    67 : '/assets/weather/rain.svg',
    71 : '/assets/weather/snowfall.svg',
    73 : '/assets/weather/snowfall.svg',
    75 : '/assets/weather/snowfall.svg',
    77 : '/assets/weather/snowfall.svg',
    80 : '/assets/weather/rain.svg',
    81 : '/assets/weather/rain.svg',
    82 : '/assets/weather/rain.svg',
    85 : '/assets/weather/snowfall.svg',
    86 : '/assets/weather/snowfall.svg',
    95 : 'assets/weather/thunder.svg',
    96 : 'assets/weather/thunder.svg',
    99 : 'assets/weather/thunder.svg',
  };

  public static readonly weather_night : Record<number, string> = {
    0 : '/assets/weather/clear_night.svg',
    1 : '/assets/weather/partly_cloudly_night.svg',
    2 : '/assets/weather/partly_cloudly_night.svg',
  };
  
  public static readonly suggested_break_icon  = '/assets/suggested-break-icon.svg';
  public static readonly exclamation_mark_icon  = '/assets/exclamation-mark.png';

  public static readonly thumbs_up_icon = '/assets/thumbs-up.png';
  public static readonly thumbs_down_icon = '/assets/thumbs-down.png';
  public static readonly complete_action_icon = '/assets/complete.png';
  public static readonly incomplete_action_icon = '/assets/incomplete.png';
  public static readonly pin_icon = '/assets/pin.png';
  public static readonly fireworks_icon = '/assets/fireworks.png';

  public static readonly score_suggestion_score = '/assets/scorecards/suggestion-score.svg';

  public static readonly reminder : Record<string, string> = {
    exclamationMark: 'assets/exclamation-mark.png',
    info: 'assets/info.png',
    star: 'assets/star.png',
  };

}