import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Scenario } from '../../store/Demo/Demo';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import DropdownMenu from '../Shared/DropdownMenu/DropdownMenu';

type IProps = {
  scenario?: Scenario,
  customContent?: React.ReactNode;
  hasNoOptions?: boolean;
  deleteAction?: Function;
  onClick: Function;
  isAdmin: boolean;
};

function ScenarioPreviewBox(props: IProps){
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const navigate = useNavigate();
  function editScenario(id: number | undefined){
    setShowDropdown(false);
    navigate(`/scenario-builder/${id}`);
  }

  function deleteScenarioPrompt(){
    setShowDeleteConfirmation(true);
    setShowDropdown(false);
  }

  function deleteScenario(){
    setShowDeleteConfirmation(false);
    if(props.deleteAction){
      props.deleteAction(props.scenario?.id);
    }
  }

  return (
    <>
      <div className='scenario-preview-box'>
        {
          (!props.hasNoOptions && props.isAdmin )&&
          <div>
            <div className='scenario-preview-icon' 
              onClick={() => setShowDropdown(!showDropdown)}/>
            <DropdownMenu isOpen={showDropdown}
              menu={[
                <button key=''
                  onClick={() => editScenario(props.scenario?.id)}>Edit</button>,
                <button key='' 
                  onClick={() => deleteScenarioPrompt()}>Delete</button>,
              ]}
            />
          </div>
        }
        <div className='scenario-preview-content'
          onClick={() => props.onClick()}>
          <p>
            {props.customContent ?? (props.scenario && props.scenario.title)}
          </p>
        </div>
      </div>
      
      {showDeleteConfirmation &&
        <ConfirmationModal  
          title='Are you sure?'
          description='Do you want to delete this scenario?'
          onContinue={() => deleteScenario()}
          continueText='Yes'
          onCancel={() => setShowDeleteConfirmation(false)}
          cancelText='No'
        />
      }
    </>
  );
}

export default ScenarioPreviewBox;