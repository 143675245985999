/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface HeadsUpState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;
    targets: Targets;
}

export interface Targets {
    id: string;
    wasteAmount: number;
    wasteTarget: number;
    revenueAmount: number;
    revenueTarget: number;
    npsScore: number;
    npsTarget: number;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestHeadsUpTargetsAction extends AnyAction {
    type: 'REQUEST_HEADS_UP_TARGETS';
}

export interface ReceiveHeadsUpTargetsAction extends AnyAction {
    type: 'RECEIVE_HEADS_UP_TARGETS';
    payload: any;
}

export interface FailedHeadsUpTargetsAction extends AnyAction {
    type: 'FAILED_HEADS_UP_TARGETS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestHeadsUpTargetsAction | ReceiveHeadsUpTargetsAction | FailedHeadsUpTargetsAction;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestHeadsUpTargets: (storeId: string, date: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.headsUp && appState.headsUp.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_HEADS_UP_TARGETS',
        http: {
          verb: 'GET',
          endpoint: `api/statistics/headsUpTargets?reportingEntityID=${storeId}&reportingDate=${date}`,
          successAction: 'RECEIVE_HEADS_UP_TARGETS',
          failureAction: 'FAILED_HEADS_UP_TARGETS',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: HeadsUpState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  targets: {
    id: '',
    wasteAmount: 0,
    wasteTarget: 100,
    revenueAmount: 0,
    revenueTarget: 100,
    npsScore: 0,
    npsTarget: 100,
  },
};

export const reducer: Reducer<HeadsUpState> = (state: HeadsUpState | undefined,
  incomingAction: Action):HeadsUpState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_HEADS_UP_TARGETS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_HEADS_UP_TARGETS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      targets: action.payload,
    };
  case 'FAILED_HEADS_UP_TARGETS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  default:
    return state;
  }
};