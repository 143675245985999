/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Animated } from 'react-animated-css';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { ApplicationState } from '../../../store';
import * as SuggestionStore from '../../../store/Suggestion/Suggestion';
import * as AuthenticationStore from '../../../store/Authentication/Authentication';
import * as MorningBrewStore from '../../../store/MorningBrew/MorningBrew';
import * as HeadsUpStore from '../../../store/HeadsUp/HeadsUp';

import { connect, ConnectedProps } from 'react-redux';
import TrainingSuggestion from './TrainingSuggestion';
import DateUtils from '../../../helpers/DateUtils';
import HeadsUpTargets from '../../Shared/HeadsUp/HeadsUpTargets';
import Suggestion from '../../Suggestion/Suggestion/Suggestion';
import { ANIMATION_DURATION, DEFAULT_SUGGESTION, LOADING_SUGGESTION, SortByPriorityAndObscurity } from '../../Suggestion/SuggestionUtils';
import ShakeAnimation from './animations/ShakeAnimation';

const mapState = (state: ApplicationState) => ({
  suggestionState: state.suggestion,
  authentication: state.authentication,
  morningBrewState: state.morningBrew,
});

const mapDispatch = {
  ...SuggestionStore.actionCreators,
  ...AuthenticationStore.actionCreators,
  ...MorningBrewStore.actionCreators,
  ...HeadsUpStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = {
  adminMode?: boolean,
  selectedScoringEntity?: string,
  handleAdminViewBack?: Function
} & PropsFromRedux;


const Home: React.FC<IProps> = (props) => {
  const [userIsSwiping, setUserIsSwiping] = useState(false);
  const [hasBeenAnimated, setHasBeenAnimated] = useState(false);
  const [suggestions, setSuggestions] = useState<SuggestionStore.Suggestion[]>(props.suggestionState.suggestions);
  const [currentSuggestion, setCurrentSuggestion] = useState<SuggestionStore.Suggestion>();

  // static variables
  const maxStackedCards = 3;
  const oneMinute = 60000;
  const prevProps = useRef(props);

  const { isLoading, displayTrainingSuggestions } = props.suggestionState;
  
  useEffect(() => {
    const { requestSuggestions, adminMode, authentication, requestHeadsUpTargets } = props;
    const interval = setInterval(checkForNewSuggestions, oneMinute);

    if(!hasBeenAnimated) {
      setTimeout(() => setHasBeenAnimated(true), 1000);
    }
    const selectedScoringEntity = props.selectedScoringEntity ? props.selectedScoringEntity : authentication.selectedScoringEntity;

    // Make sure that there is a selected scoring entity before requesting suggestions and heads up targets
    if(selectedScoringEntity?.length > 0) {
      requestSuggestions(selectedScoringEntity, DateUtils.getLocalDateString(DateUtils.dateTimeFormat), adminMode);
      requestHeadsUpTargets(selectedScoringEntity, new Date().toISOString());
    }

    return () => {
      clearInterval(interval);
      if(adminMode) {
        prevProps.current.suggestionState.suggestions = [];
      }
    };
  }, []);

  useEffect(() => {
    const { suggestionState } = props;
    const { suggestions } = suggestionState;
    const [newTopSuggestion] = suggestions.sort(SortByPriorityAndObscurity);

    if(currentSuggestion && newTopSuggestion) {
      //if top suggestion is different and it came from the backend
      if(currentSuggestion && currentSuggestion.title !== newTopSuggestion.title && 
        !prevProps.current.suggestionState.suggestions.includes(newTopSuggestion)) {
        setHasBeenAnimated(false);
        setTimeout(() => setHasBeenAnimated(true), 1000);
      }
    }
    setCurrentSuggestion(newTopSuggestion);
    setSuggestions(suggestions);

    prevProps.current.suggestionState.suggestions = suggestions;
  }, [props.suggestionState]);


  const checkForNewSuggestions = () => {
    const { requestSuggestions, adminMode, authentication } = props;
    const selectedScoringEntity = props.selectedScoringEntity ? props.selectedScoringEntity : authentication.selectedScoringEntity;

    if (!userIsSwiping && !displayTrainingSuggestions) {
      requestSuggestions(selectedScoringEntity, DateUtils.getLocalDateString(DateUtils.dateTimeFormat), adminMode);
    }
  };

  const renderSuggestionStack = () => {
    const stackedCards = suggestions.slice(1, maxStackedCards + 1)
      .map((s, i) => <div key={s.id}
        className={`card card-${i+1}`}></div>);
  
    return stackedCards.length > 0 && (
      <div className='stacked-cards'>
        {stackedCards}
      </div>
    );
  };

  return (
    <div className="app">
      <div className="body-wrapper">
        {
          props.adminMode && <button onClick={() => props.handleAdminViewBack && props.handleAdminViewBack()}
            className='back-btn'>{'<-- Back to scoring entities'}</button>
        }
        <h1 className="title">Digital Store Assistant</h1>
      </div>
     
      {
        displayTrainingSuggestions &&
          <Animated
            key='default'
            animationIn="bounceIn"
            animationOut="bounceOut"
            animationInDuration={ANIMATION_DURATION}
            animationOutDuration={ANIMATION_DURATION}
            isVisible={true}>
            <TrainingSuggestion />
          </Animated>
      }

      {
        suggestions.length === 0 && !displayTrainingSuggestions &&
        (
          <Animated
            key='default'
            animationIn="bounceIn"
            animationOut="zoomOut"
            animationInDuration={ANIMATION_DURATION}
            animationOutDuration={ANIMATION_DURATION}
            isVisible={true}>
            {
              isLoading 
                ? 
                <Suggestion
                  loading={true}
                  suggestion={LOADING_SUGGESTION}
                  blockSwipe={true}
                />
                :
                <Suggestion
                  suggestion={DEFAULT_SUGGESTION}
                  loading={isLoading}
                  blockSwipe={true}
                />
            }
          </Animated>
        )
      }

      {
        currentSuggestion && !displayTrainingSuggestions && 
        <ShakeAnimation isAnimating={!hasBeenAnimated}>
          <>
            <Suggestion 
              key={currentSuggestion.id + currentSuggestion.timeSlot}
              suggestion={currentSuggestion}
              blockSwipe={props.authentication.mimicScoringEntity.length > 0}
              onSwipe={(value: boolean) => setUserIsSwiping(value)}
            />
            {
              currentSuggestion && !displayTrainingSuggestions && renderSuggestionStack()
            }
          </>
        </ShakeAnimation>
      }
      <HeadsUpTargets />
    </div>
  );
};

export default connector(Home);