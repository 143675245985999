/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MorningBrewState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    isUpdated: boolean;

    metrics: Metric[],
    trends: Trend[]
}

export interface Metric {
    metric: string;
    value: number;
    increaseGood?: boolean;
    change?: number;
    format: string;
}

export enum MetricFormatTypes {
    String = 'string',
    Integer = 'integer',
    Decimal = 'decimal',
    Currency = 'currency'
}

export interface Trend {
    metric: string;
    values: Value[]
}

export interface Value {
    label: string;
    value: number
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestMorningBrewAction extends AnyAction {
    type: 'REQUEST_MORNING_BREW';
}

export interface ReceiveMorningBrewAction extends AnyAction {
    type: 'RECEIVE_MORNING_BREW';
    payload: any;
}

export interface FailedMorningBrewAction extends AnyAction {
    type: 'FAILED_MORNING_BREW';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestMorningBrewAction | ReceiveMorningBrewAction | FailedMorningBrewAction;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestMorningBrew: (storeId: string, date: string):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.morningBrew && appState.morningBrew.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_MORNING_BREW',
        http: {
          verb: 'GET',
          endpoint: `api/statistics/morningStatistics?reportingEntityID=${storeId}&reportingDate=${date}`,
          successAction: 'RECEIVE_MORNING_BREW',
          failureAction: 'FAILED_MORNING_BREW',
        }, 
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: MorningBrewState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  metrics: [],
  trends: [],
};

export const reducer: Reducer<MorningBrewState> = (state: MorningBrewState | undefined,
  incomingAction: Action):MorningBrewState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_MORNING_BREW':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_MORNING_BREW':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      trends: action.payload.trends || [],
      metrics: action.payload.metrics || [],
    };
  case 'FAILED_MORNING_BREW':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  default:
    return state;
  }
};

