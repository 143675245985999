type Props = {
    title: string,
    value: number,
    currency?: string,
    child?: JSX.Element,
    className?: string,
    valueColour?: string,
    hideHeaderBreak? : boolean,
}
  
function StatusCard(props: Props) {
  const { title, value, className, currency, child, valueColour } = props;
  return (
    <div className={`status-card ${className ?? '' }`}>
      <div className="header">{title}</div>
      {!props.hideHeaderBreak && <hr/>}
      <div className="body">
        <div className={`content ${child ?? 'child-component'}`}>
          {
            child ?? <>
              <div className={`value ${valueColour ?? ''}`}>
                {value}
              </div>
              {
                currency && <div className="currency">
                  {currency}
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default StatusCard;