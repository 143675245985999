/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';
import { Scenario } from './Demo';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScenarioSuggestionState {
  isLoading: boolean;
  isErrored: boolean;
  errorMessage: string;
  selectedScenario?: Scenario;
}

export interface ScenarioSuggestion {
    id: number,
    scenarioId: number,
    scenarioTitle: string,
    title: string;
    description: string;
    priority: number;
    obscurity: number;
    category: string;
    showAfter: number;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestScenarioSuggestionsAction extends AnyAction {
    type: 'REQUEST_SCENARIO_SUGGESTIONS';
}
export interface FailedScenarioSuggestionsAction extends AnyAction {
    type: 'FAILED_REQUESTED_SCENARIO_SUGGESTIONS';
}

export interface ReceiveScenarioSuggestionsAction extends AnyAction {
    type: 'RECEIVE_REQUESTED_SCENARIO_SUGGESTIONS';
    payload: any;
}
export interface UpdateScenarioSuggestionAction extends AnyAction {
    type: 'UPDATE_SCENARIO_SUGGESTION';
}

export interface ReceiveUpdateScenarioSuggestionAction extends AnyAction {
    type: 'RECEIVE_UPDATE_SCENARIO_SUGGESTION';
    payload: any;
}

export interface FailedUpdateScenarioSuggestionAction extends AnyAction {
    type: 'FAILED_UPDATE_SCENARIO_SUGGESTION';
}

export interface CreateScenarioSuggestionAction extends AnyAction {
    type: 'CREATE_SCENARIO_SUGGESTION';
}

export interface ReceiveCreateScenarioSuggestionAction extends AnyAction {
    type: 'RECEIVE_CREATE_SCENARIO_SUGGESTION';
    payload: any;
}

export interface FailedCreateScenarioSuggestionAction extends AnyAction {
    type: 'FAILED_CREATE_SCENARIO_SUGGESTION';
}

export interface ClearScenarioSuggestionAction extends AnyAction {
    type: 'CLEAR_SCENARIO_SUGGESTIONS';
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestScenarioSuggestionsAction 
                        | UpdateScenarioSuggestionAction
                        | FailedScenarioSuggestionsAction 
                        | ReceiveScenarioSuggestionsAction 
                        | ReceiveUpdateScenarioSuggestionAction 
                        | FailedUpdateScenarioSuggestionAction 
                        | CreateScenarioSuggestionAction
                        | ReceiveCreateScenarioSuggestionAction 
                        | FailedCreateScenarioSuggestionAction
                        | ClearScenarioSuggestionAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestScenarioSuggestions: (scenarioId: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scenarioBuilder && appState.scenarioBuilder.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_SCENARIO_SUGGESTIONS',
        http: {
          verb: 'GET',
          endpoint: `/api/scenariosuggestion/${scenarioId}/view-all`,
          successAction: 'RECEIVE_REQUESTED_SCENARIO_SUGGESTIONS',
          failureAction: 'FAILED_REQUESTED_SCENARIO_SUGGESTIONS',
        }, 
      });
    }
  },

  deleteScenarioSuggestion: (id: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scenarioBuilder && appState.scenarioBuilder.isLoading === false) {
      dispatch({ 
        type: 'UPDATE_SCENARIO_SUGGESTION',
        http: {
          verb: 'DELETE',
          endpoint: `/api/scenariosuggestion/${id}`,
          successAction: 'RECEIVE_UPDATE_SCENARIO_SUGGESTION',
          failureAction: 'FAILED_UPDATE_SCENARIO_SUGGESTION',
        }, 
      });
    }
  },

  createScenarioSuggestion: (scenarioSuggestion: ScenarioSuggestion):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scenarioBuilder && appState.scenarioBuilder.isLoading === false) {
      dispatch({ 
        type: 'CREATE_SCENARIO_SUGGESTION',
        http: {
          verb: 'POST',
          endpoint: '/api/scenariosuggestion/',
          successAction: 'RECEIVE_CREATE_SCENARIO_SUGGESTION',
          failureAction: 'FAILED_CREATE_SCENARIO_SUGGESTION',
          body: {
            ...scenarioSuggestion,
          },
        }, 

      });
    }
  },

  updateScenarioSuggestion: (scenarioSuggestion: ScenarioSuggestion):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scenarioBuilder && appState.scenarioBuilder.isLoading === false) {
      dispatch({ 
        type: 'UPDATE_SCENARIO_SUGGESTION',
        http: {
          verb: 'PUT',
          endpoint: '/api/scenariosuggestion/',
          payload: scenarioSuggestion,
          successAction: 'RECEIVE_UPDATE_SCENARIO_SUGGESTION',
          failureAction: 'FAILED_UPDATE_SCENARIO_SUGGESTION',
          body: {
            ...scenarioSuggestion,
          },
        }, 
      });
    }
  },

  clearSuggestions: ():
    AppThunkAction<any> => (dispatch) => {
    dispatch({ 
      type: 'CLEAR_SCENARIO_SUGGESTIONS',
    });
  },
};


// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: ScenarioSuggestionState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  selectedScenario: undefined,
};

export const reducer: Reducer<ScenarioSuggestionState> = (state: ScenarioSuggestionState | undefined,
  incomingAction: Action):ScenarioSuggestionState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_SCENARIO_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_REQUESTED_SCENARIO_SUGGESTIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      selectedScenario: action.payload.data,
    };
  case 'FAILED_REQUESTED_SCENARIO_SUGGESTIONS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the danavigateta.',
      isErrored: true,
      isLoading: false,
    };
  case 'UPDATE_SCENARIO_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_UPDATE_SCENARIO_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      selectedScenario: action.payload,
    };
  case 'FAILED_UPDATE_SCENARIO_SUGGESTION':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'CREATE_SCENARIO_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_CREATE_SCENARIO_SUGGESTION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      selectedScenario: action.payload,
    };
  case 'FAILED_CREATE_SCENARIO_SUGGESTION':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'CLEAR_SCENARIO_SUGGESTIONS':
    return {
      ...state,
      selectedScenario: undefined,
    };
  default:
    return state;
  }
};