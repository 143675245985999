import { useEffect } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import * as DemoStore from  '../../../store/Demo/Demo';
import * as AuthStore from '../../../store/Authentication/Authentication';
import ScenarioPreviewBox from '../ScenarioPreviewBox';
import { useNavigate } from 'react-router';
import { Scenario } from '../../../store/Demo/Demo';
import PermissionHelper from '../../../helpers/PermissionHelper';
import { Loading } from '../../Shared/Loading/Loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  demoState: state.demo,
});

const mapDispatch = {
  ...AuthStore.actionCreators,
  ...DemoStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

function DemoPage(props: IProps){
  const permHelper = new PermissionHelper(props.authentication.permissions);
  const scenarios = useSelector((state: ApplicationState) => state.demo.scenarios);
  const navigate = useNavigate();
  const { requestScenarios, deleteScenario, enterDemoMode, selectDemoScenario, deselectDemoScenario, exitDemoMode } = props;

  useEffect(() => {
    deselectDemoScenario();
    exitDemoMode();
    requestScenarios();
  }, []);

  function goToAddScenario(){
    navigate('/scenario-builder');
  }

  function goToScenario(scenario: Scenario){
    enterDemoMode();
    selectDemoScenario(scenario);
    setTimeout(() => {
      navigate('/');
    }, 500);
  }

  return (
    <div className="app">
      <div className="body-wrapper">
        <div className='title-wrapper'>
          <h1 className='demo-heading title'>Demo</h1>
        </div>
        {
          props.demoState.isLoading ?
            <Loading/>
            :
            <div className='demo-content-wrapper'>
              { permHelper.IsAdmin() &&
            <ScenarioPreviewBox hasNoOptions
              onClick={() => goToAddScenario()}
              isAdmin={true}
              customContent={
                <div className='demo-plus-sign'>+</div>
              } />
              }
              {scenarios.map((scenario: Scenario) => {
                return(
                  <ScenarioPreviewBox scenario={scenario}
                    onClick={() => goToScenario(scenario)}
                    deleteAction={deleteScenario}
                    isAdmin={permHelper.IsAdmin()}
                    key={scenario.id} />
                );},
              )}
            </div>
        }
      </div>
    </div>
  );
}

export default connector(WithRouter(DemoPage));