/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppThunkAction } from '../index';
import { Action, Reducer, AnyAction } from 'redux';
import { ScenarioSuggestion } from './ScenarioBuilder';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DemoState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    scenarios: Scenario[];
    selectedScenario: Scenario | undefined;
}

export interface Scenario {
    id: number;
    title: string;
    suggestions: ScenarioSuggestion[];
    initializedAt: number;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestScenariosAction extends AnyAction {
    type: 'REQUEST_SCENARIOS';
}

export interface ReceiveScenariosAction extends AnyAction {
    type: 'RECEIVE_REQUESTED_SCENARIOS';
    payload: any;
}

export interface FailedScenariosAction extends AnyAction {
    type: 'FAILED_REQUESTED_SCENARIOS';
}

export interface UpdateScenarioAction extends AnyAction {
    type: 'UPDATE_SCENARIO';
}

export interface ReceiveUpdateScenarioAction extends AnyAction {
    type: 'RECEIVE_UPDATE_SCENARIO';
    payload: any;
}

export interface FailedUpdateScenarioAction extends AnyAction {
    type: 'FAILED_UPDATE_SCENARIO';
}

export interface SelectDemoScenario extends AnyAction {
    type: 'SELECT_DEMO_SCENARIO';
    payload: any;
}

export interface DeselectDemoScenario extends AnyAction {
    type: 'DESELECT_DEMO_SCENARIO';
}

export interface UpdateSelectedScenario extends AnyAction {
    type: 'UPDATE_SELECTED_SCENARIO';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestScenariosAction | ReceiveScenariosAction | FailedScenariosAction | UpdateScenarioAction 
                         | ReceiveUpdateScenarioAction | FailedUpdateScenarioAction | SelectDemoScenario | DeselectDemoScenario
                         | UpdateSelectedScenario;
                         
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestScenarios: ():
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.demo && appState.demo.isLoading === false) {
      dispatch({ 
        type: 'REQUEST_SCENARIOS',
        http: {
          verb: 'GET',
          endpoint: 'api/scenario',
          successAction: 'RECEIVE_REQUESTED_SCENARIOS',
          failureAction: 'FAILED_REQUESTED_SCENARIOS',
        }, 
      });
    }
  },
  deleteScenario: (scenarioId: number):
    AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.demo && appState.demo.isLoading === false) {
      dispatch({ 
        type: '`UPDATE_SCENARIO`',
        http: {
          verb: 'DELETE',
          endpoint: `api/scenario/${scenarioId}`,
          successAction: 'RECEIVE_UPDATE_SCENARIOS',
          failureAction: 'FAILED_UPDATE_SCENARIOS',
        }, 
      });
    }
  },
  selectDemoScenario: (scenario: Scenario):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'SELECT_DEMO_SCENARIO',
        payload: scenario,
      });
    }
  },
  deselectDemoScenario: ():
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'DESELECT_DEMO_SCENARIO',
      });
    }
  },

  updateSelectedScenario: (suggestions: ScenarioSuggestion[]):
    AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({ 
        type: 'UPDATE_SELECTED_SCENARIO',
        payload: suggestions,
      });
    }
  },
};

// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: DemoState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  scenarios: [],
  selectedScenario: undefined,
};

export const reducer: Reducer<DemoState> = (state: DemoState | undefined,
  incomingAction: Action):DemoState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_SCENARIOS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_REQUESTED_SCENARIOS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      scenarios: action.payload.data,
    };
  case 'FAILED_REQUESTED_SCENARIOS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'UPDATE_SCENARIO':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_UPDATE_SCENARIO':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      scenarios: action.payload.data,
    };
  case 'FAILED_UPDATE_SCENARIO':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred while receiving the data.',
      isErrored: true,
      isLoading: false,
    };
  case 'SELECT_DEMO_SCENARIO':
    return {
      ...state,
      selectedScenario: {
        ...action.payload,
        initializedAt: Date.now(),
      },
    };
  case 'DESELECT_DEMO_SCENARIO':
    return {
      ...state,
      selectedScenario: undefined,
    };
  case 'UPDATE_SELECTED_SCENARIO':
    return {
      ...state,
      selectedScenario: {
        ...state.selectedScenario!,
        suggestions: action.payload,
      },
    };
  default:
    return state;
  }
};