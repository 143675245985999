import { ApexOptions } from 'apexcharts';

const chartMaxValueColour = '#f2cd00';
const chartDefaultColour = '#d4d4d4';
const chartTitleColour = '#7a7979';
const chartCurrentTimeColour = '#e04e39';
const blackChartColour = '#403e3d';

export const options: ApexOptions = {
  chart: {
    stacked: true,
    background: '#fafcff',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: true,
    },
  },

  states: {
    hover: {
      filter: {
        type: '',
        value: 0,
      },
    },

    active: {
      filter: {
        type: '',
        value: 0,
      },
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth:'70',
      dataLabels: {
        position: 'top',
      },
    },
  },

  dataLabels: {
    enabled: true,
    formatter: function (val: any, opt: any) {
      const data = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex];
      if (data && data.length) {
        const values = data.split('-');
        return [values[0],`£${Math.floor(values[1])}` ];
      }
      return val;
    },
    style: {
      colors: [
        function ({ value, seriesIndex }: { value: number, seriesIndex: number }) {
          if(seriesIndex === 1 || seriesIndex === 2) {
            return blackChartColour;
          } else {
            return blackChartColour;
          }
        }],
    },
  },

  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },

    yaxis: {
      lines: {
        show: false,
      },
    },
  },

  xaxis: {
    labels: {
      show: true,
    },

    categories: [''],

    axisBorder: {
      show: true,
      color: chartDefaultColour,
      offsetX: 0,
      offsetY: 0,
    },
  },

  yaxis: {
    labels: {
      show: false,
    },

    axisBorder: {
      show: true,
      color: chartDefaultColour,
      offsetX: 0,
      offsetY: 0,
    },
  },

  legend: {
    show: true,
    position: 'bottom',
    onItemHover: {
      highlightDataSeries: true,
    },
    showForSingleSeries: true,
  },

  colors: [chartDefaultColour, chartMaxValueColour, chartCurrentTimeColour],
  title: {
    text: 'Yesterday\'s Popular Times',
    align: 'center',
    margin: 20,
    offsetX: 0,
    offsetY: 0,
    floating: true,
    style: {
      fontSize: '21px',
      fontWeight: 'bold',
      fontFamily: '',
      color: chartTitleColour,
    },
  },
};

export const series: StatisticsData[] = [
  {
    name: 'Transactions per Hour',
    data: [],
  },
  {
    name: 'Hour with the highest transactions',
    data: [],
  },
  {
    name: 'Current hour',
    data: [],
  },
];

export interface StatisticsData {
  name: string,
  data: any[]
}
