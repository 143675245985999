import { RefObject, useEffect, useRef } from 'react';

type IProps = {
   isOpen: boolean,
   menu: React.ReactNode[],
}
function DropdownMenu (props: IProps) {
  return (
    <>
      {props.isOpen &&
        <div className="dropdown">
          <ul className="menu">
            {props.menu.map((menuItem, index) => (
              <li key={index}
                className="menu-item">{menuItem}</li>
            ))}
          </ul>
        </div>
      }
    </>
  );
}

export default DropdownMenu;