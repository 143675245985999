import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';
import { ApplicationState } from '../../../store';
import { useSelector } from 'react-redux';
import { useState } from 'react';

type Props = {
    className?: string,
};

function HeadsUpTargets(props: Props) {
  const targets = useSelector((state: ApplicationState) => state.headsUp.targets);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  function toggleCollapsed(){
    setCollapsed(!collapsed);
  }

  return (
    <>
      {
        targets.id && 
        <div className={`targets-wrapper ${props.className ?? '' }`}>
          <div>
            <button type="button"
              className="targets-bar"
              onClick={toggleCollapsed}>
              <div className="targets-header">
                <div className="targets-header-left">{collapsed ? '+' : '-'}</div>
                Targets
                <div className="targets-header-right">{collapsed ? '+' : '-'}</div>
              </div>
            </button>
          </div>
          <div className={`targets-collapsible-content ${collapsed ? 'collapsed' : ''}`}>
            <CircularProgressBar 
              className='inverted'
              value={targets?.wasteAmount ?? 0} 
              maxValue={targets?.wasteTarget ?? 100} 
              title={'Waste'}
              lowerIsBetter
              showPercentage />
            <CircularProgressBar value={targets?.npsScore ?? 0}
              className='inverted' 
              maxValue={targets?.npsTarget ?? 100} 
              title={'NPS'}
              showValue />
            <CircularProgressBar value={targets?.revenueAmount ?? 0} 
              className='inverted'
              maxValue={targets?.revenueTarget ?? 100} 
              title={'Revenue'}
              showPercentage />
          </div>
        </div>
      }
    </>
  );
}

export default HeadsUpTargets;