import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../../store';
import { WithRouter } from '../../Shared/Routing/WithRouter';
import * as QueueStatusStore from '../../../store/Demo/QueueStatus';
import * as AuthStore from '../../../store/Authentication/Authentication';
import { Loading } from '../../Shared/Loading/Loading';
import MorningBrewCard from '../../MorningBrew/Common/MorningBrewCard';
import { MetricFormatTypes } from '../../../store/MorningBrew/MorningBrew';
import CircularProgressBar from '../../Shared/CircularProgressBar/CircularProgressBar';
import ProgressBar from '../../Shared/ProgressBar/ProgressBar';
import StatusCard from './StatusCard';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  queueStatusState: state.queueStatus,
});

const mapDispatch = {
  ...AuthStore.actionCreators,
  ...QueueStatusStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux;

function QueueStatusPage(props: IProps) {
  const { queueStatusState, requestQueueStatus } = props;
  const { queueStatus } = queueStatusState;
  const { count, timeToServeInSec, averageWaitTimeInSec } = queueStatus;

  useEffect(() => {
    requestQueueStatus();

    const queueStatusInterval = setInterval(() => {
      requestQueueStatus();
    }, 15000);

    return () => {
      clearInterval(queueStatusInterval);
    };
  }, []);

  const convertSecondsToMinutes = (seconds: number) => {
    return seconds >= 60 ? Math.floor((seconds % 3600) / 60) : seconds;
  };

  const getTimeSuffix = (seconds: number) => {
    if (seconds === 1) {
      return 'second';
    } else if (seconds < 60) {
      return 'seconds';
    } else if (seconds < 120) {
      return 'minute';
    } else {
      return 'minutes';
    }
  };


  return (
    <div className="app">
      <div className="body-wrapper">
        <div className='title-wrapper'>
          <h1 className='demo-heading title'>Queue Status</h1>
        </div>
        {
          queueStatusState.isLoading &&
          <Loading />
        }
        {
          !queueStatusState.isLoading && queueStatusState &&
          <div className='queue-status-wrapper'>
            <div className='queue-status-row'>
              <StatusCard title='Current Queue Size'
                currency='people'
                value={count.queueIn} />

              <StatusCard title='Queue Abandonment'
                currency='people'
                value={count.queueAbandonment} />

              <StatusCard title='Average Time To Serve'
                value={timeToServeInSec}
                className={'lg'}
                child={
                  <CircularProgressBar
                    value={convertSecondsToMinutes(timeToServeInSec)}
                    valueSuffix={getTimeSuffix(timeToServeInSec)}
                    maxValue={timeToServeInSec >= 60 ? 10 : 600 }
                    lowerIsBetter={true}
                    showValue={true}
                    className={'inverted lg'}
                  />
                }
              />
            </div>
            <div className='queue-status-row'>
              <StatusCard title='Current Queue Time'
                value={averageWaitTimeInSec}
                className='full-width text-left'
                hideHeaderBreak={true}
                child={
                  <ProgressBar value={convertSecondsToMinutes(averageWaitTimeInSec)}
                    maxValue={averageWaitTimeInSec >= 60 ? 10 : 600 }
                    showValue={true}
                    valueSuffix={getTimeSuffix(averageWaitTimeInSec)} />
                }
              />
            </div>

          </div>
        }
      </div>
    </div>
  );
}

export default connector(WithRouter(QueueStatusPage));